export var ConnectBinanceSteps;
(function (ConnectBinanceSteps) {
    ConnectBinanceSteps[ConnectBinanceSteps["EnterKey"] = 0] = "EnterKey";
    ConnectBinanceSteps[ConnectBinanceSteps["Success"] = 1] = "Success";
    ConnectBinanceSteps[ConnectBinanceSteps["Error"] = 2] = "Error";
})(ConnectBinanceSteps || (ConnectBinanceSteps = {}));
export const EXTERNAL_LINKS = {
    BINANCE_SETTINGS: '',
    CREATE_BINANCE_ACCOUNT: '',
};
