import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import IconWithText from '@shared/ui/display/IconWIthText';
import { StepContainer } from '@shared/ui/sidebars/shared';
import Button from '@shared/ui/buttons/Button';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as CheckmarkIcon } from '@icons/wolfkit-solid/checkmark-with-check-circle-solid.svg';
import useBinanceConnect from './useBinanceConnect';
const Success = ({ handleClose }) => {
    const { t } = useTranslation();
    const { goToNextStep } = useBinanceConnect({ handleClose });
    return (_jsx(SidebarInner, { children: _jsx(StepContainer, { content: (_jsx(IconWithText, { text: t('overview.connect_binance_exchange.success.title'), IconElement: (_jsx(Icon, { IconComponent: CheckmarkIcon, size: 70, keepOriginalColor: true })) })), buttons: (_jsx(Button, { onClick: () => goToNextStep(), size: 'large', fullWidth: true, children: t('overview.connect_binance_exchange.success.continue_btn') })) }) }));
};
export default Success;
