var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import Button from '@shared/ui/buttons/Button';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import { IsDefined } from '@utils/js-ts';
import { ExchangeSidebar, getFormInitValues } from '@entities/exchange';
import ConnectBinanceExchange from '../ConnectBinanceExchange';
const ConnectExchangeButton = (_a) => {
    var { onClick } = _a, rest = __rest(_a, ["onClick"]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { data: exchanges, } = useGetExchangeAccountsQuery();
    const dispatch = useDispatch();
    const handleSidebarOpen = (ev) => {
        setIsSidebarOpen(true);
        dispatch(getFormInitValues(ExchangeSidebar.ConnectBinanceExchange));
        if (onClick) {
            onClick(ev);
        }
    };
    const handleClose = useCallback(() => {
        setIsSidebarOpen(false);
    }, [dispatch]);
    if (IsDefined(exchanges) && exchanges.length !== 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({}, rest, { onClick: handleSidebarOpen })), _jsx(Sidebar, { isOpen: isSidebarOpen, children: _jsx(ConnectBinanceExchange, { handleClose: handleClose }) })] }));
};
export default ConnectExchangeButton;
