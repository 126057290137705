import * as yup from 'yup';
import { REGEXP_LATIN_SYMBOLS } from '@app/constants/validation';
import { ConnectExchangeErrorCodes } from '@shared/ui/sidebars/errorCodes';
export const enterKeyValidationSchema = (translateFn) => yup.object().shape({
    name: yup.string().min(1, translateFn('overview.connect_binance_exchange.enter_data.errors.should_not_be_empty')).matches(REGEXP_LATIN_SYMBOLS, translateFn('overview.connect_binance_exchange.enter_data.errors.only_latin')).max(50, 'overview.connect_binance_exchange.enter_data.errors.max_50_chars'),
    apiKey: yup.string().min(1, translateFn('overview.connect_binance_exchange.enter_data.errors.should_not_be_empty')),
    secretKey: yup.string().min(1, translateFn('overview.connect_binance_exchange.enter_data.errors.should_not_be_empty')),
});
export const handleResponseError = ({ translateFn, setError, error, exchangeType, }) => {
    if (error === null || error === void 0 ? void 0 : error.code) {
        if (error.code === ConnectExchangeErrorCodes[exchangeType].INVALID_KEYS) {
            setError('apiKey', {
                message: translateFn('portfolio.create_portfolio.steps.error.api_error.invalid_keys', { ns: 'common' }),
            });
            setError('secretKey', {
                message: translateFn('portfolio.create_portfolio.steps.error.api_error.invalid_keys', { ns: 'common' }),
            });
        }
    }
    return error;
};
